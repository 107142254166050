import { InviteScreen } from "pages/Invite/Invite";
import {
    BrowserRouter,
    Routes as RoutesDOM,
    Route,
    Navigate
} from "react-router-dom";

export const Routes = () => {
    return (
        <BrowserRouter>
            <RoutesDOM>
                <Route path="/enviar-convite" element={<InviteScreen />} />
                <Route path="*" element={<Navigate to="/enviar-convite" />} />
            </RoutesDOM>
        </BrowserRouter>
    )
}