import { RouterProvider } from "react-router-dom";

import { Routes } from "routes/Routes";

function App() {
  return (
    <Routes />
  );
}

export default App;
